@import "../../style/mixins";

.provider-form-container {
  @include main-contianer(100%);
  display: flex;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
  z-index: 0;
}

@media only screen and (max-width: 950px) {
  .provider-form-container {
    padding: 30px 0;
  }
}

.provider-form-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 200px;
  align-items: center;
  border-radius: 20px;
  overflow: scroll;
}

@media only screen and (max-width: 950px) {
  .provider-form-wrapper {
    padding: 30px 2%;
  }
}

.provider-form-title {
  @include header-title;
  margin-bottom: 15px;
}

.provider-form-type {
  @include title-text(#2033A7);
  text-align: left;
  width: 100%;
  margin-bottom: 15px;
}

.provider-form-input-wrapper {
  width: 100%;
  padding: 15px;
  border: 1px solid #2033A7;
  border-radius: 15px;
  margin-bottom: 15px;
}

.provider-form-multi-wrapper {
  display: flex;
  width: 100%;
}

.provider-form-submit-btn {
  @include primary-panel-button(#204CEC, #FFFFFF);
  display: block;
  font-size: 1.5rem;
  width: 40%;
  min-width: 300px;
  border-radius: 15px;
}
