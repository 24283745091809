@import "./variables";

/************************
        General
************************/
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-size: 16px;
}

html, body, #wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background: #FAFAFA;
}

p {
  font-size: 1rem;
  line-height: 21px;
  color: #000000;
}

a {
  text-decoration: none;
}

button {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background: none;
}

button:hover {
  cursor: pointer;
}

#app-wrapper, .advance-vital-app {
  position: relative;
  width: 100%;
  height: 100%;
}

.advance-vital-app {
  background-color: #F5F6FF;
}

h1, h2, h3, h4, p, a, button, label, input {
  font-family: 'Source Sans Pro', sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}
