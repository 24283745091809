@import "../../style/mixins";

.success-failure-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  overflow: scroll;
}

@media only screen and (max-width: 600px) {
  .success-failure-container {
    padding: 30px 2%;
  }
}

.success-failure-title {
  @include header-title;
  margin-bottom: 15px;
}

.success-failure-text {
  @include primary-text(#5C5981);
  text-align: center;
  margin-bottom: 15px;
  padding: 0;
}

.success-failure-img {
  width: 70%;
}
