@mixin main-contianer($container-width) {
  position: relative;
  width: $container-width;
  height: 100%;
  padding: 30px 2.5%;
}

@mixin shadow-box($element-width) {
  position: relative;
  padding: 30px 3%;
  width: $element-width;
  border-radius: 15px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 6px 3px #B3B9C2;
}

@mixin header-title {
  font-size: 1.75rem;
  font-weight: 600;
  color: #626BE2;
}

@mixin title-text($font-color) {
  font-size: 1.5rem;
  color: $font-color;
}

@mixin primary-text($font-color) {
  font-size: 1.125rem;
  color: $font-color;
}

@mixin primary-panel-button($button-color, $font-color) {
  padding: 10px 30px;
  margin-right: 30px;
  border-radius: 30px;
  background-color: $button-color;
  color: $font-color;
}

@mixin secondary-panel-button($button-color) {
  padding: 5px 15px;
  margin-right: 30px;
  border-radius: 30px;
  border: 1px solid $button-color;
  background-color: #FFFFFF;
  color: $button-color;
}

@mixin tertiary-panel-button {
  padding-top: 1px;
  font-size: 1.025rem;
  border-bottom: 2px solid #CE74D2;
  color: #5C5981;
}

@mixin input-field {
  width: 100%;
  padding: 10px;
  text-align: center;
  font-size: 1.75rem;
  outline: none;
  color: #5C5981;
  border: none;
}
