@import "../../style/mixins";

.form-input-container {
  width: 100%;
  padding: 0 15px;
  margin-bottom: 15px;
}

.form-input-label {
  @include primary-text(#5C5981);
  display: block;
  margin-bottom: 10px;
}

.form-input-wrapper {
  @include shadow-box(100%);
  padding: 0;
  overflow: hidden;
}

.form-input {
  @include input-field;
}

@media only screen and (max-width: 550px) {
  .form-input {
    font-size: 1.25rem;
  }
}

.form-input::placeholder {
  color: #AAB3C7;
}

.form-input-error {
  color: #A72020;
  margin-top: 10px;
}
