@import "../../style/mixins";

.processing-overlay-container, .processing-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  .processing-overlay-container {
    padding: 0 5%;
  }
}

.processing-overlay {
  opacity: 0.5;
  background-color: #FFFFFF;
}

.processing-text-wrapper {
  padding: 30px;
  border-radius: 10px;
  background-color: #5BA0D9;
  z-index: 2;
}

.processing-text {
  @include header-title;
  color: #FFFFFF;
}

@media only screen and (max-width: 600px) {
  .processing-text {
    font-size: 1.25rem;
    color: #FFFFFF;
  }
}
